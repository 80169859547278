.positive-button {
    display: block;
    line-height: 48px;
    text-align: center;
    border-radius: 12px;
    background: #685dd8;
    color: #fff;
    font-family: main-font-bold;
    cursor: pointer;
}

.negative-button {
    display: block;
    border: 1px solid #685dd8 !important;
    text-align: center;
    line-height: 48px;
    font-family: main-font-bold;
    border-radius: 12px;
    cursor: pointer;
}