.x-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 36px rgba(1, 3, 69, .26);
  padding: 20px;
}

.m8 {
  margin: 8px;
}

.m16 {
  margin: 16px;
}

.m12 {
  margin: 12px;
}

.nm {
  margin: 0;
}

.mb-4 {
  margin-bottom: 16px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-1 {
  margin-top: 4px;
}


.html-container * {
  font-family: main-font;
  background: transparent !important;
  font-size: 14px;
}

.html-container {
  font-family: main-font;
  background: transparent !important;
  text-align: justify;
  font-size: 14px;
}

.x-title {
  font-family: main-font-bold;
  font-size: 14px;
}


.Toastify__toast-container * {
  font-family: main-font-bold;
}

.Toastify__toast-container {
  left: 0 !important;
  right: 0 !important;
  margin: 8px auto !important;
  max-width: calc(100% - 24px);
  border-radius: 12px !important;
  overflow: hidden;
}

.Toastify__toast-container--top-center {
  left: 50% !important;
  transform: translateX(50%) !important;
  right: 50% !important;
  margin: 8px auto !important;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 8px !important;
    border-radius: 12px !important;
  }
}


.button-loader {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 32px 0 #fff, -32px 0 #fff;
  animation: flash 0.5s ease-out infinite alternate;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
}

.button-loader-dark {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #333;
  box-shadow: 32px 0 #333, -32px 0 #333;
  animation: flash-dark 0.5s ease-out infinite alternate;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
}

@keyframes flash {
  0% {
    background-color: #FFF2;
    box-shadow: 16px 0 #FFF2, -16px 0 #FFF;
  }
  50% {
    background-color: #FFF;
    box-shadow: 16px 0 #FFF2, -16px 0 #FFF2;
  }
  100% {
    background-color: #FFF2;
    box-shadow: 16px 0 #FFF, -16px 0 #FFF2;
  }
}

@keyframes flash-dark {
  0% {
    background-color: #3332;
    box-shadow: 16px 0 #3332, -16px 0 #333;
  }
  50% {
    background-color: #333;
    box-shadow: 16px 0 #3332, -16px 0 #3332;
  }
  100% {
    background-color: #3332;
    box-shadow: 16px 0 #333, -16px 0 #3332;
  }
}

.image-avatar {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.hidden {
  display: none !important;
}

.toman {
  width: 20px;
  height: 20px;
}

iframe {
  max-width: 100%;
  min-height: 500px;
}

