.cd.dialog-cover {
    background: rgba(71, 71, 71, 0.3);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 4000;
    opacity: 0;
    display: none;
    transition: 300ms;

}
@media (max-width: 767px) {
    .cd.dialog-cover {
        transition: 400ms;
    }
}
.cd.dialog-cover.active {
    display: block;
}
.cd.dialog-cover.show {
    opacity: 1;
}
.cd.dialog-cover .dialog {
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
    border-radius: 8px;
    transition: 100ms;
    max-width: 96%;
    max-height: 96%;
}

@media (min-width: 768px) {

}
.cd.dialog-cover .dialog {
    width: 0;
    height: 0;
}
.cd.dialog-cover .dialog.active {
    width: 600px;
    height: 600px;
}

.cd.dialog-cover .dialog .dialog-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 64px);
    /*width: calc(100%);*/
    padding: 0  16px;
}
.cd.dialog-cover .dialog .dialog-header {
    /*height: 48px;*/
    /*border-bottom: 1px solid #eee;*/
    /*line-height: 54px;*/
    width: calc(100% - 16px);
    padding-top: 6px;
    margin: auto;
    padding-right: 8px;
    display: flex;
    justify-content: space-between;
}

.cd.dialog-cover .dialog .dialog-header >div{
    font-size: 18px;
    font-family: main-font-bold;
}

.cd.dialog-cover .dialog .dialog-header .close {
    padding-left: 8px;
    cursor: pointer;
}

    .cd.dialog-cover .dialog-body{
    font-size: 14px;
}

