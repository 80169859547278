.dialog-cover {
  position: fixed;
  width: 100%;
  height: 100%;
  max-width: 500px;
  top: 0;
  z-index: -9999;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: 500ms;
}

.dialog-body {
  font-size: 13px;
  padding: 0 8px 16px 8px;
  color: #222;
  height: calc(100% - 40px);
  overflow-y: auto;
}
.dialog-header {
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  line-height: 40px;
  font-size: 13px;
  padding: 0 8px;
}
.close-icon svg{
  position: relative;
  top: 6px;
}
.close-icon{
  cursor: pointer;
}

.dialog-cover.active {
  z-index: 999999999999;
  background: rgba(24, 24, 24, 0.2) ;
}

.dialog {
  background: #fff;
  min-height: 100px;
  bottom: -150%;
  position: absolute;
  left: 0;
  right: 0;
  transition: 400ms;
}

.dialog.active {
  bottom: 0;
}