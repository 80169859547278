.navigation-menu-container {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 24, 24, 0.3);
  z-index: -1;
  opacity: 0;
  transition: 200ms;
}

.navigation-menu-container.active {
  opacity: 1;
  z-index: 99999;
}

.navigation {
  width: 300px;
  height: 100vh;
  position: fixed;
  right: -300px;
  top: 0;
  bottom: 0;
  background: #fff;
  transition: 200ms;
  z-index: 999999;
}

.navigation.active {
  right: 0;
}

.menu-icon {
  width: 48px;
  height: 36px;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  position: relative;
  right: 8px;
}


.navigation-header {

}

.navigation-header {
  height: 32px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 8px;
  width: calc(100% - 16px);
}


.navigation-header img {
  width: 128px;
  height: 24px;
}
