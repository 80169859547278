:root {
    --font-family: main-font;
    --fs-400: clamp(1.13rem, calc(1.06rem + 0.33vw), 1.31rem);
    --clr-primary-200: rgb(255, 255, 255);
    --clr-accent-300: #0b869f;
    --clr-accent-400: #0b869f;
    --clr-shadow-200: rgb(184 184 184);
    --clr-shadow-300: rgb(92, 92, 92);
}

.input {
    /*max-width: 12.5rem;*/
    height: 48px;
    border-radius: 8px;
    color: var(--clr-shadow-300);
    border: 1px solid var(--clr-shadow-200);
    font-family: main-font;
}

.input-wrapper input {
    width: 100%;
    color: #111;
    text-align: center;
    font-size: 16px;
}

.input-text.ls{
    letter-spacing: 2px;
    font-size: 18px !important;
    font-family: main-font-bold;


}

.input-wrapper {
    height: fit-content;
    position: relative;
    line-height: 14px;
    width: calc(100% - 8px);
    display: inline-block;
    /*min-width: 260px;*/
    /*max-width: 12.5rem;*/
    height: 48px;
    background-color: #fff;
}

.input-text {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    outline: none;
    background: none;
    transition: all 160ms ease-in;
}

.input-text:hover {
    border-color: var(--clr-accent-300);
}

.input-text:focus {
    border-color: var(--clr-accent-400);
    border: 2px solid var(--clr-accent-400);
    transition: 0ms !important;
}

.input-text:focus ~ .input-label,
.input-text:not(:placeholder-shown) ~ .input-label,
.input-text:not(:placeholder-shown):focus ~ .input-label {
    top: -0.5rem;
    font-size: 0.8rem;
    right: 0.6rem;
    color: var(--clr-accent-400);
}

.input-text:not(:focus):not(:placeholder-shown) ~ .input-label {
    color: var(--clr-shadow-200);
    background-color: var(--clr-primary-200);
}

.input-label {
    position: absolute;
    right: 0.4rem;
    top: 20px;
    padding: 0 0.5rem;
    color: var(--clr-shadow-300);
    cursor: text;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in, color 200ms ease-in;
    background-color: #fff;
}
