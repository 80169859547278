.plate {
    width: 260px;
    margin: auto;
    border: 1px solid #eee;
    background: #fff;
    display: flex;
    direction: ltr;
    height: 48px;
    border-radius: 12px;
    padding: 5px;
    overflow: hidden;
    position: relative;
}

.char .dash4{
    left: 106px !important;
}
.char {
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    /*width: 30px;*/
    width: 44px;
    z-index: 2;
    left: 96px;
    top: 0;
    line-height: 64px;
    cursor: pointer;
    height: 60px;
}


.divider {
    width: 1px;
    height: 70px;
    background: #eee;
    position: absolute;
    /*left: 180px;*/
    left: 200px;
    top: 0;
}

.plate input:active {
    border: 0;
}

.plate input:focus {
    border: 0;
}

.plate input {
    width: 50px;
    height: 40px;
    border: 0;
    padding-left: 8px;
    font-family: main-font;
}

.plate .dash1 {
    display: inline-block;
    width: 8px;
    height: 2px;
    background: #aaa;
    margin: 2px;
    position: absolute;
    left: 16px;
    bottom: 16px;
    z-index: 2;
}

.flag {
    background: #0c306a;
    width: 30px;
    height: 48px;
    border-radius: 8px 4px 4px 8px;

}


.plate .dash2 {
    display: inline-block;
    width: 8px;
    height: 2px;
    background: #aaa;
    margin: 2px;
    position: absolute;
    left: 30px;
    bottom: 16px;
    z-index: 2;
}


.plate .dash3 {
    display: inline-block;
    width: 8px;
    height: 2px;
    background: #aaa;
    margin: 2px;
    position: absolute;
    left: 32px;
    bottom: 4px;
    z-index: 2;
}

.inpContainer3 .dash2 {
    left: 28px;
}

.inpContainer3 .dash1 {
    left: 16px;
}

.inpContainer {
    position: relative;
}

.inpContainer3 input {
    padding-left: 8px;
    margin-left: 8px;
}

.inpContainer3 {
    position: relative;
    top: 6px;
    width: 64px;
}

.inpContainer2 input {
    position: relative;
    left: 32px;
    padding-left: 0;
    width: 64px;
}

.inpContainer2 {
    position: relative;
    left: 48px;
    padding-left: 0;
}



.plate input {
    letter-spacing: 3px;
    height: 48px;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    bottom: 2px;
    text-align: center;
}


.inpContainer2 input{
    position: relative;
    left: 0px;
    padding-left: 0;
}


.plate input:focus {
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}


.country {
    position: absolute;
    right: 26px;
    font-weight: bold;
    font-size: 10px;
    color: #666;
    top: 2px;
}

.inpContainer input{
    text-align: center;
    width: 38px;
    left: 2px;
}

.inpContainer2 .dash1 {
    left: 16px;
}

.inpContainer2 .dash2 {
    left: 28px;
}

.inpContainer2 .dash3 {
    left: 40px;
    bottom: 16px;
}


.inpContainer3 {
    left: 6px;
}

.inpContainer3 .dash1{
    bottom: 22px;
}

.inpContainer3 .dash2{
    bottom: 22px;
}




.inpContainer3 input {
    padding-left: 0;
    margin-left: 0;
    width: 58px;
}

.flag {
    font-size: 8px;
    color: #fff;
    line-height: 8px;
}

.flagCountry {
    padding: 4px;
}

.flag img {
    border-radius: 3px;
    margin-left: 5px;
    margin-top: 5px;

}


.dialogBody {
    direction: rtl;
    padding: 4px;
    text-align: center;
}

.charRow {
    width: 35px;
    height: 35px;
    font-size: 16px;
    font-weight: 300;
    line-height: 35px;
    text-align: center;
    margin: 4px;
    display: inline-block;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 0 1px 0 #eee;
    border: 1px solid #eee;
    background: #fff;
    font-family: main-font-bold;
    color: #555;
}


.dash4 {
    display: inline-block;
    width: 8px;
    height: 2px;
    background: #aaa;
    margin: 2px;
    position: absolute;
    left: 100px;
    bottom: 21px;
    z-index: 2;
}

.dash5 {
    display: inline-block;
    width: 8px;
    height: 2px;
    background: #aaa;
    margin: 2px;
    position: absolute;
    left: 110px;
    bottom: 21px;
    z-index: 2;
}

.dash4Caret {
    display: inline-block;
    width: 8px;
    height: 20px;
    margin: 2px;
    position: absolute;
    left: 0;
    top: 22px;
    bottom: 12px;
    z-index: 2;
    font-size: 10px;
    font-weight: bold;
}


/*end plate*/

.bannerContainer {
    position: relative;
    background: linear-gradient(270deg, #0145a8 0%, #2fa0fe 100%);
}

.noBorder{
    padding-top: 4px;
}

@media (min-width: 1000px) {
    .bannerContainer {
        position: relative;
        background: rgb(1,69,168);
        background: linear-gradient(270deg, rgba(1,69,168,1) 0%, rgba(27,124,221,1) 50%, rgba(47,160,254,1) 100%);
    }
}


.plateContainer {
    position: absolute;
    background: #fff;
    width: 90%;
    left: 0;
    right: 0;
    overflow: hidden;
    margin: auto;
    bottom: -100px;
    z-index: 22;
    max-width: 800px;
    /*transition: 300ms;*/
    justify-content: space-between;
    border-radius: 16px;
    border: 1px solid #eee;

    min-width: 280px;
}


.inquiryBoxContainer {
    padding-top: 8px;
}

.boxRow {
    border: 1px solid #eee;
    box-shadow: 0 0 2px 0 #eee;
    padding: 8px;
    background: #eee;
    border-radius: 8px;
    min-width: 260px !important;
    margin-left: 0 !important;
    margin-right: 8px !important;
    max-width: 300px;
}


.boxTitle {
    font-size: 16px;
    font-weight: bold;
    line-height: 32px;
    padding-left: 12px;
}

.boxDesc {
    padding-top: 8px;
    font-size: 12px;
}

/*inquiry page*/
.inquiryModelRowTitle {
    padding-right: 8px;
    padding-top: 16px;
}

.inquiryModelRowImage {
    width: 48px;
    height: 48px;
    margin-right: 8px;
}

.inquiryModelRow {
    border: 1px solid #eee;
    text-align: right;
    line-height: 40px;
    margin: 8px;
}

/*end inquiry page*/

/*banner*/

/*@media (min-width: 0) {*/
/*    .bannerBg {*/
/*        height: 200px;*/
/*        background-size: 100% auto;*/

/*        background-image: url("/assets/app/i-banner-sm.jpg");*/
/*        background-repeat: no-repeat;*/
/*    }*/
/*}*/

@media (min-width: 400px) {
    .bannerBg {
        height: 200px;
    }
}


@media (min-width: 460px) {
    .bannerBg {
        height: 240px;
    }
}

@media (min-width: 600px) {
    .bannerBg {
        height: 280px;
    }
}
@media (min-width: 750px) {
    .bannerBg {
        height: 320px;
    }
}
@media (min-width: 860px) {
    .bannerBg {
        height: 360px;
    }
}

/*@media (min-width: 1000px) {*/
/*    .bannerBg {*/
/*        background-image: url("/assets/app/i-banner.jpg");*/
/*    }*/
/*}*/


/*@media (min-width: 1200px) {*/
/*    .bannerBg {*/
/*        background-image: url("/assets/app/i-banner.jpg");*/
/*    }*/
/*}*/

/*@media (min-width: 1430px) {*/
/*    .bannerBg {*/
/*        background-image: url("/assets/app/i-banner.jpg");*/
/*    }*/
/*}*/

/*@media (min-width: 1630px) {*/
/*    .bannerBg {*/
/*        background-image: url("/assets/app/i-banner.jpg");*/
/*    }*/
/*}*/







.plateContainer {
    bottom: -52px;
}

.plateAdded {
    bottom: -100px;
}

.hasNotDefaultPluque {
    bottom: -130px;
}



/*end-banner*/
