.article-row{
    box-shadow: 0 0 7px 0 #ccc;
    padding: 8px;
    margin-top: 16px;
    border-radius: 8px;
    display: block;
    text-decoration: none;
}

.article-row img{
    width: 100%;
    border-radius: 8px;
}

.article-row .description-part h3{
    font-size: 14px;
    font-family: main-font-bold;
    font-weight: normal;
    color: #333;
    margin: 0;
    padding-right: 8px;
}

