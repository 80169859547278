.row1 {
    display: flex;
    background: #eee;
    padding: 4px;
    line-height: 30px;
}

.row2 {
    line-height: 30px;
    display: flex;
    background: white;
    padding: 4px;
}



.if-details {
    text-align: justify;
    font-size: 14px;
    padding: 16px;
}


.if-details p{
    font-weight: bold;
    font-size: 18px;
}

.if-details blockquote{
    font-size: 14px;
    padding: 0;
    margin: 16px auto;
}

.fih1{
    font-size: 18px;
    color: #333;
    margin: 0;
}

.type-info{
    color: #555;
    font-size: 12px;
    margin: 0 0 16px 0;

}