.loading-button {
    width: calc(100%);
    display: block;
    /*background: var(--primary-color);*/
    cursor: pointer;
    height: 48px;
    line-height: 48px;
    /*margin-top: 32px;*/
    color: #fff;
    border-radius: 8px;
    text-align: center;
    font-family: main-font-bold;
    position: relative;
    font-size: 15px;

}


.loading-button.primary {
    background: #EF4056;
}

.loading-button.nc{
    cursor: inherit !important;
}

.loading-button.disabled {
    background: dimgrey;
}

.loading-button.success {
    font-size: 14px;
    background: rgb(10, 175, 88) !important;
    color: #fff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, .2), 0px 2px 2px 0px rgba(0, 0, 0, .14), 0px 1px 5px 0px rgba(0, 0, 0, .12)
}

.loading-button.green {
    font-size: 14px;
    background: #0b869f !important;
    color: #fff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, .2), 0px 2px 2px 0px rgba(0, 0, 0, .14), 0px 1px 5px 0px rgba(0, 0, 0, .12)
}
