html {
  background: #fff;
}

body {
  /*background: #F7F7F7;*/
  /*height: 100vh;*/
}


.nav-item-container {
  z-index: 999;
}

.nav-item-container .nav-item {
  cursor: pointer;
  text-align: center;

}

.nav-item-container .nav-image img {
  /*width: 28px;*/
  margin: auto;
}

footer .nav-item-container .nav-image img {
  /*width: 48px !important;*/
  /*height: 48px !important;*/
  margin: auto;
}


#main-footer {
  /*height: 102px;*/
  height: 58px;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  left: 0;
  background: transparent;
  right: 0;
}

.footer-white {
  background: #fff;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 58px;
  z-index: 9;
}

.footer-shadow {
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, #fff 58px, rgba(255, 255, 255, 0.0) 100%);
  bottom: 0;
  height: 130px;
  left: 0;
  max-height: 100%;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 8;

}

.nav-item-container .nav-image {
  text-align: center;
  /*margin-top: 4px;*/
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 4px;
  /*box-shadow: 0 0 4px 0 #aaa;*/
}


.nav-item-container {
  box-shadow: 0 0 7px 0 #eee;
  height: 76px;
  padding-top: 12px;
  position: fixed;
  bottom: 16px;
  min-width: 280px;
  max-width: 360px;
  margin: 0 20px;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  display: flex;
  justify-content: space-evenly;
  font-size: 11px;
  text-align: center;
  font-weight: normal;
  left: 0;
  right: 0;
}

.nav-image {
  text-align: center;
  margin: auto auto 8px;
}

.home-icons-container {
  position: static !important;
  display: flex;
  border-radius: 0;
  width: calc(100% - 2px);
  margin: auto;
  padding-top: 20px;
  text-align: center;
  box-shadow: none;
  background: transparent;
}

.blog-part .header a {
  line-height: 28px;
  font-size: 13px;

}

.blog-part {
  margin-bottom: 16px;
}

.blog-part .header {
  padding-right: 24px;
  background: #fff;
  width: calc(100%);
  height: 32px;
  line-height: 42px;
  margin: 32px auto 0 auto;
  border-radius: 8px;
  padding-top: 8px;
}


.home-item {
  color: #333;
  text-decoration: none;
  height: 48px !important;
  width: 48px !important;
}

.home-item {
  width: calc(33% - 8px);
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.blog-part .title {
  color: #333;
  font-size: 14px;
  line-height: 38px;
  text-decoration: dashed;
}

.blog-row {
  color: #333;
  text-decoration: none;
  display: block;
  background: #fff;
  border-radius: 8px;
  overflow-x: hidden;
  box-shadow: 0 0 7px 0 #eee;
}

.blog-row h2 {
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  height: 60px;
  line-height: 20px;
  max-lines: 3;
  overflow: hidden;
  text-align: justify;
  padding: 4px;
}

.article-date {
  font-size: 12px;
  padding-right: 8px;
  color: #333;
  vertical-align: middle;
}


.vam {
  vertical-align: middle;
}

.mt-5 {
  margin-top: 24px;
}

.mt-4 {
  margin-top: 16px;
}

footer {
  max-width: 500px;
}

html {
  max-width: 500px;
  margin: auto;

}

footer {
  min-width: 250px;
  max-width: 500px;
  left: 0 !important;
  right: 0 !important;
  margin: auto;
}


.question-survey label {
  padding-right: 16px;
  font-size: 13px;
}

.input-main {
  margin-top: 16px;
}

.input-main label{
  top: 1rem;
}


.question-survey input {
  display: block;
  width: calc(100% - 48px);
  margin: auto;
  height: 42px;
  font-family: main-font;
  border: 1px solid #ccc;
  padding-right: 16px;
  border-radius: 4px;
  background: transparent;
}

.question-survey textarea {
  display: block;
  width: calc(100% - 48px);
  margin: auto;
  font-family: main-font;
  border: 1px solid #ccc;
  padding-right: 16px;
  border-radius: 4px;
  background: transparent;
  height: 100px;
}

.btn-cancel, .btn-success, .btn-warning {
  display: inline-block;
  min-width: 80px;
  padding: 0 16px;

  background: #1e92ff;
  color: #fff;
  border-radius: 4px;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
}

.btn-cancel {
  background: #ff4444;
}

.btn-warning {
  background: #FFEB3B;
  color: #222;
}


.btn-warning.small, .btn-cancel.small, .btn-success.small {
  min-width: 60px;
  line-height: 30px;
  font-size: 13px;
  padding: 0 8px;
  text-align: center;
  cursor: pointer;
}

.b8 {
  background: linear-gradient(0deg, #22AA59, #00FE88 100%);
}

.b9 {
  background: linear-gradient(0deg, #0e686e, #20e5f3 100%);
}

.b1 {
  background: linear-gradient(0deg, #05111a, #06208c 100%);
}

.b3 {
  background: linear-gradient(0deg, #0493F9, #93D2FF 100%);
}

.b2 {
  background: linear-gradient(0deg, #e5357f, #de548f 100%);
}

.b4 {
  background: linear-gradient(0deg, #3343E1, #7985FF 100%);
}

.b5 {
  background: linear-gradient(0deg, #F20E0B, #FF9A98 100%);
}

.b6 {
  background: linear-gradient(0deg, #C400B4, #F25CEE 100%);
}

.b7 {
  background: linear-gradient(0deg, #16BEB3, #3BECDF 100%);
}

.home-icons-container .home-item {
  width: 33.33% !important;
  line-height: 26px;
}

.bn-container {
  left: 0;
  right: 0;
  margin: auto;
}


