#ios-guide {
    background: #fff;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999999999;
    display: flex;
    align-content: center;
    align-items: center;

}

.installable-bordered {
    border: 2px solid #eee;
    margin: 16px 32px;
    padding: 8px;
    border-radius: 8px;
}




