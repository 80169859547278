.link {
  color: #1e92ff;
  border-bottom: 1px dashed #1e92ff;
  cursor: pointer;
}

.button-1, .button-2 {
  display: inline-block;
  padding: 4px 16px 2px 16px;
  line-height: 22px;
  background: #01b2ac;
  color: #fff;
  font-size: 13px;
  border-radius: 8px;
  cursor: pointer;
}

.button-2 {
  background: #ff4444;
}