.alert{
    padding:12px 16px;
    border-radius: 12px;
    font-size: 13px;
    text-align: justify;
}

.alert-success{
    background: #16BEB3;
    color: #fff;

}
.alert-warning{
    background: #fff2ea;
    color: #222;
    border: 1px solid #ead3c9;

}
