:root {
  --primary-color: #ef4056;
  --success-color: #199f9f;
}

.login-logo img {
  width: 180px;
}

.x-container {
  width: 100%;
  padding: 0 24px;
}

.login-logo {
  text-align: center;
  margin: auto;
}

.auth-container {
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;
  width: 100%;
  padding-top: 100px;
}


.login-input {
  margin-top: 16px;
  width: calc(100% - 32px);
  height: 48px;
  border: 0;
  text-align: center;
  border-bottom: 2px solid var(--primary-color);
  border-radius: 8px;
  background: #eee;
  padding: 0 16px;
  outline: none;
  font-size: 20px;
  letter-spacing: 1px;
  font-family: main-font-bold;
}

.login-input.input-success {
  border-bottom: 2px solid var(--success-color);
}


.input-hint {
  font-size: 12px;
  display: block;
  color: var(--primary-color);
  font-family: main-font-fat;
  text-align: right;
  margin-top: 8px;
}

.input-hint.success {
  color: var(--success-color);
}


.subtext a {
  padding: 0 4px;
  text-decoration: none;
  color: var(--success-color);
}

.subtext {
  font-size: 12px;
  margin-top: 16px;
  color: #333;
}




