.inquiry-header .auth {
    width: 48px;
    text-align: center;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.inquiry-header .logo img {
    width: 120px;
    padding-top: 8px;
    cursor: pointer;
}

.inquiry-header {
    height: 48px;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    align-items: center;
    position: fixed;
    justify-content: space-between;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 9;
    max-width: 500px;
}

.nav-icon {
    width: 48px;
    height: 48px;
    text-align: center;
    cursor: pointer;
    margin-right: 8px;
}

.nav-icon svg {
    padding-top: 12px;
    transform: rotateY(180deg);
}


.inquiry-main {
    margin-top: 72px;
}


/**/

.faq-row {
    background: #f7f7f7;
    margin-top: 8px;
    padding: 16px;
    font-size: 13px;
    color: #222;
    border-radius: 12px;
    cursor: pointer;
    border: 1px solid #ddd;
    font-family: main-font-bold;
}


.faq-desc {
    padding-right: 8px;
}

.faq-desc * {
    font-size: 13px !important;
    color: #333;
}


.ld-container table {
    border-collapse: collapse;
    margin: 25px 0;
    text-align: center;
    font-size: 0.9em;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.ld-container table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}


.ld-container table th,
.ld-container table td {
    padding: 12px 8px;
}

.ld-container table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.ld-container table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.ld-container table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.ld-container table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}


.ld-container {
    background: #f3f3f3;
    padding: 16px;
    text-align: justify;
    font-size: 13px;
}

.ld-container a {
    color: #22aee1;
    border-bottom: 1px dashed #22aee1;
    text-decoration: none;
    padding: 0 2px;
}

.ld-container h2 {
    padding-top: 0;
    margin-top: 0;
    font-size: 16px;
    font-family: main-font;
    color: #204a9e !important;
    margin-bottom: 0;
    padding-bottom: 0;
}



