body {
  padding: 0;
  margin: 0;
  /*background: #f7f7f7;*/
}

#main-header {
  box-shadow: 0 0 7px 0 #eee;
}

#main-header .logo {
  width: 125px;
  height: auto;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.h-navigation {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  padding: 0;
}

main{
  /*border: 1px solid #eee;*/
  height: 100%;
}


.tab-icon-container {
  text-align: center;
}


.h-navigation li {
  width: 50%;
  font-size: 15px;
}


.h-navigation a {
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: center;
  color: #888;
}

.h-navigation a.active {
  color: #222;
  border-bottom: 1px solid #bbb;
}


.h-navigation svg {
  width: 20px;
}


.header-top .icons svg {
  width: 16px;
  fill: #333;
  margin: 8px;
}

.header-top .icons {
  display: flex;
  width: 60%;
  justify-content: flex-end;
}

.tac {
  text-align: center;
}

.header-top {
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
}



.stories .story {
  overflow: hidden;
  width: 54px;
  height: 54px;
  padding: 1px;
  border-radius: 100%;
  margin: auto;
  background: linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7)
}

.stories .story img {
  border-radius: 100%;
  width: calc(100% - 1px);
  margin-top: 2px;
  height: calc(100% - 1px);
  margin-right: 2px
}

.story-wrapper {
  background: #fff;
  border-radius: 100%;
  width: calc(100% - 2px);
  height: calc(100% - 2px)
}

.stories .swiper-wrapper {
  justify-content: center;
  display: flex
}

.stories.swiper {
  background: 0 0
}

.stories .swiper-slide {
  text-align: center;
  background: 0 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center
}

.dont-show-mob {
  display: block !important
}

.story-viewer .close {
  color: #fff;
  z-index: 9;
  font-size: 28px;
  position: absolute;
  top: 8px;
  left: 8px;
  cursor: pointer;
  display: inline-block;
  width: 32px;
  height: 32px;
  text-align: center
}

.story-viewer .type-container img {
  max-width: 100%;
  max-height: 100%;
  margin: auto
}

.story-viewer .type-container {
  height: 100%;
  min-width: 300px;
  max-width: 500px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  background: #111;
  align-items: center;
  align-content: center
}

.story-viewer {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
  z-index: 9999
}

.story-container {
  width: 64px;
  height: 84px;
  text-align: center;
  margin: 16px 0 0 8px
}


.first-story {
  margin-right: 8px !important;
}
.stories .story {
  overflow: hidden;
  width: 54px;
  height: 54px;
  padding: 1px;
  border-radius: 100%;
  margin: auto;
  background: linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7);
  position: relative;
}
.stories .story img {
  border-radius: 100%;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
}
.story-wrapper {
  background: #fff;
  border-radius: 100%;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}
@media (min-width: 500px) {
  .stories .swiper-wrapper {
    justify-content: center;
    display: flex;
  }
}
.stories.swiper {
  background: 0 0;
}
.stories .swiper-slide {
  text-align: center;
  background: 0 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dont-show-mob {
  display: block !important;
}
.story-viewer .close {
  color: #fff;
  z-index: 9;
  font-size: 28px;
  position: absolute;
  top: 8px;
  left: 8px;
  cursor: pointer;
  display: inline-block;
  width: 32px;
  height: 32px;
  text-align: center;
}
.story-viewer .type-container img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.story-viewer .type-container {
  height: 100%;
  min-width: 300px;
  max-width: 500px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  background: #111;
  align-items: center;
  align-content: center;
}
.story-viewer {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
  z-index: 9999;
}
.story-container {
  width: 64px;
  height: 54px;
  text-align: center;
  margin: 16px 0 0 4px;
}
.story-timer-container {
  position: fixed;
  top: 8px;
  height: 8px;
  left: 8px;
  right: 8px;
  max-width: 478px;
  border-radius: 8px;
  margin: auto;
  background: #fff;
  z-index: 99;
}
.story-timer-container .story-timer-placeholder {
  position: absolute;
  width: calc(100% - 8px);
  height: 4px;
  background: #aaa;
  left: 0;
  right: 0;
  margin: auto;
  top: 2px;
  border-radius: 4px;
}
.story-timer-container .story-timer {
  position: absolute;
  width: 5px;
  height: 4px;
  background: #333;
  right: 4px;
  margin: auto;
  top: 2px;
  max-width: 470px;
  z-index: 99;
  border-radius: 4px;
}

.home-slide{
  border-radius: 8px;
  height:160px;
  object-fit: cover;
}