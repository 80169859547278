.blog-single p {
  font-family: main-font !important;
}

.blog-single {
  text-align: justify;
  overflow-x: hidden;
  line-height: 28px;
  padding-top: 54px !important;
}

.blog-single table {
  display: block;
  max-width: 100%;
  text-align: center;
}

.blog-single a {
  border-bottom: 1px dashed #19b1ea;
  text-decoration: none;
  padding: 0 4px;
}

.blog-single img {
  max-width: 80%;
  width: 80%;
  display: block;
  height: auto !important;
  margin: auto;
}


.blog-header {
  display: flex;
  align-items: center;
  z-index: 9999;
  height: 48px;
  background: #eee;
  font-family: main-font;
  color: #000;
  line-height: 48px;
  max-lines: 1;
  white-space: nowrap;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.blog-header span {
  display: block;
  width: calc(100% - 64px);
  overflow: hidden;
}


.blog-header svg {
  display: inline-block;
  width: 40px;
  cursor: pointer;
  position: relative;
  top: 6px;

}

.blog-title {
  font-size: 13px;
  font-family: main-font-bold;
}


.blog-main-image {
  width: 100% !important;
  max-width: 100% !important;

  border-radius: 12px;
  margin: 16px 0 !important;
}