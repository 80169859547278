.btn {
    padding: 0 12px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    line-height: 48px;
    max-width: 100%;
    font-size: 16px !important;
}

.btn.block {
    width: 100%;
    display: block;
}

.btn-green:hover {
    color: #fff;
}

.btn-green {
    font-size: 14px;
    background: rgb(10, 175, 88) !important;
    color: #fff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, .2), 0px 2px 2px 0px rgba(0, 0, 0, .14), 0px 1px 5px 0px rgba(0, 0, 0, .12)
}


.red-btn:hover {
    color: #fff;
}

.btn-success {
    font-size: 14px;
    background: #0b869f !important;
    color: #fff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, .2), 0px 2px 2px 0px rgba(0, 0, 0, .14), 0px 1px 5px 0px rgba(0, 0, 0, .12)
}



