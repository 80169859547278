:root {
    --primary-color: #212121;
    --background-color: white;
    /*--font: sans-serif;*/
}


/*body {*/
/*    background: var(--background-color);*/
/*    font-family: var(--font);*/
/*    display: flex;*/
/*    justify-content: center;*/
/*}*/

/* Timeline Container */
.timeline {
    background: #f9f9f98f;
    margin: 20px auto;
    padding: 20px;
}

/* Card container */
.card {
    position: relative;
    max-width: 400px;
}

/* setting padding based on even or odd */
.card:nth-child(odd) {
    padding: 30px 0 30px 30px;
}

.card:nth-child(even) {
    padding: 30px 30px 30px 0;
}

/* Global ::before */
.card::before {
    content: "";
    position: absolute;
    width: 50%;
    border: dashed #9d9d9d;
}

/* Setting the border of top, bottom, left */
.card:nth-child(odd)::before {
    left: 0px;
    top: -4.5px;
    bottom: -4.5px;
    border-width: 5px 0 5px 5px;
    border-radius: 50px 0 0 50px;
}

/* Setting the top and bottom to "-5px" because earlier it was out of a pixel in mobile devices */
@media only screen and (max-width: 400px) {
    .card:nth-child(odd)::before {
        top: -5px;
        bottom: -5px;
    }
}

/* Setting the border of top, bottom, right */
.card:nth-child(even)::before {
    right: 0;
    top: 0;
    bottom: 0;
    border-width: 5px 5px 5px 0;
    border-radius: 0 50px 50px 0;
}

/* Removing the border if it is the first card */
.card:first-child::before {
    border-top: 0;
    border-top-left-radius: 0;
}

/* Removing the border if it is the last card  and it's odd */
.card:last-child:nth-child(odd)::before {
    border-bottom: 0;
    border-bottom-left-radius: 0;
}

/* Removing the border if it is the last card  and it's even */
.card:last-child:nth-child(even)::before {
    border-bottom: 0;
    border-bottom-right-radius: 0;
}

/* Information about the timeline */
.info {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    color: #131428;
    border-radius: 2rem;
    padding: 1rem;
    border: 1px solid #d4d4d4;
    box-shadow: 0px 0px 18px -15px black;
}

/* Title of the card */
.title {
    color: rgb(43 45 98);
    position: relative;
    margin-bottom: 1rem;
    text-align: center;
}
.title1 {
    color: rgb(43 45 98);
    position: relative;
    margin-bottom: 1rem;
    text-align: center;
}
.title2 {
    color: rgb(43 45 98);
    position: relative;
    margin-bottom: 1rem;
    text-align: center;
}
.title3 {
    color: rgb(43 45 98);
    position: relative;
    margin-bottom: 1rem;
    text-align: center;
}
.title4 {
    color: rgb(43 45 98);
    position: relative;
    margin-bottom: 1rem;
    text-align: center;
}

/* Timeline dot  */
/* .title::before {
    content: "\f21d";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 999px;
    border: 3px solid #131428;
    font-size: 26px;
    color: black;
}
.title1::before {
    content: "\211E";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 999px;
    border: 3px solid #131428;
    font-size: 26px;
    color: black;
}
.title2::before {
    content: "\211E";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 999px;
    border: 3px solid #131428;
    font-size: 26px;
    color: black;
}
.title3::before {
    content: "\f074";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 999px;
    border: 3px solid #131428;
    font-size: 26px;
    color: black;
}
.title4::before {
    content: "\f0ac";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 999px;
    border: 3px solid #131428;
    font-size: 26px;
    color: black;
} */

/* text right if the card is even  */
.card:nth-child(even)>.info>.title ,
.card:nth-child(even)>.info>.title1 ,
.card:nth-child(even)>.info>.title2 ,
.card:nth-child(even)>.info>.title3 ,
.card:nth-child(even)>.info>.title4
{
    /* text-align: right; */
}

/* setting dot to the left if the card is odd */
.card:nth-child(odd)>.info>.title::before ,
.card:nth-child(odd)>.info>.title1::before ,
.card:nth-child(odd)>.info>.title2::before ,
.card:nth-child(odd)>.info>.title3::before ,
.card:nth-child(odd)>.info>.title4::before
{
    left: -65px;
}

/* setting dot to the right if the card is odd */
.card:nth-child(even)>.info>.title::before ,
.card:nth-child(even)>.info>.title1::before ,
.card:nth-child(even)>.info>.title2::before ,
.card:nth-child(even)>.info>.title3::before ,
.card:nth-child(even)>.info>.title4::before {
    right: -65px;
}





.info p {
    text-align: center;
    line-height: 22px;
    font-weight: 400;
    font-size: 13px;
}


/** {*/
/*    font-family: Yekan !important;*/
/*}*/